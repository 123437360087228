import React, { useEffect, useState } from "react";
import "./pageTitle.css";
import { useIntl } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";

function PageTitle({ page, pageTitle }) {
  const intl = useIntl();
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const [backButton, setBackButton] = useState(false);
  // Split the page path into segments and filter out empty segments
  const pathSegments = page.split("/").filter((segment) => segment !== "");

  // Function to construct a full path from a list of segments
  const constructPath = (segments, index) => {
    // Create a path from the segments array up to the given index
    return "/" + segments.slice(0, index + 1).join("/");
  };
  // useEffect(() => {
  //   if (path.includes("/agentId")) {
  //     setBackButton(true);
  //   } else if (path.includes("/gameList")) {
  //     setBackButton(true);
  //   } else if (path.includes("/memberList")) {
  //     setBackButton(true);
  //   } else if (path.includes("/agentList")) {
  //     setBackButton(true);
  //   } else {
  //     setBackButton(false);
  //   }
  // }, [path]);
  return (
    <div className="pagetitle">
      <div className="d-flex">
        <h1>{pageTitle}</h1>
      </div>
    </div>
  );
}

export default PageTitle;
