import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { MemberList } from "../../modules/memberlist/components/MemberList";
import { MemberGameList } from "../../modules/memberlist/components/MemberGameList";
import { MemberReportList } from "../../modules/memberlist/components/MemberReport";

const MemberContext = createContext();

const MemberWrapper = () => {
  const value = {};

  return (
    <MemberContext.Provider value={value}>
      <Routes>
        <Route path="/gameList/:memberId" element={<MemberGameList />} />
        <Route path="/memberReport/:memberId" element={<MemberReportList />} />
      </Routes>
    </MemberContext.Provider>
  );
};

export { MemberWrapper };
