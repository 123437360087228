import React from "react";
import "./header.css";
import Nav from "./Nav";

function Header() {
  const handleToggleSideBar = () => {
    document.body.classList.toggle("toggle-sidebar");
  };
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <i
        className="bi bi-list toggle-sidebar-btn"
        onClick={handleToggleSideBar}
      ></i>
      <Nav />
    </header>
  );
}

export default Header;
