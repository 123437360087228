import { useEffect, useState } from "react";
import * as actions from "../redux/MemberAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { TransferDialog } from "./transferDialog/TransferDialog";
import { CreateGameIdDialog } from "./CreateGameIdDialog";

export const MemberGameList = () => {
  const { memberId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selCompanyName, setSelCompanyName] = useState(null);
  const [selBalance, setSelBalance] = useState(null);
  const [selCompanyCode, setSelCompanyCode] = useState(null);
  const [selGameRate, setSelGameRate] = useState(null);
  const [selGameUsername, setSelGameUsername] = useState(null);
  const [selGamePass, setSelGamePass] = useState(null);
  const [selGameUrl, setSelGameUrl] = useState(null);
  const [selGameExist, setGameExist] = useState(null);
  const memberState = useSelector(({ member }) => member, shallowEqual);
  const [swalProps, setSwalProps] = useState({});
  const [initialUsername, setInitialUsername] = useState(0);
  const [pathFromSearch, setPathFromSearch] = useState("");
  const [gameBalances, setGameBalances] = useState({});
  const location = useLocation();

  useEffect(() => {
    const initialUsername = location.state?.userName;
    const pathFromSearch = location.state?.searchName;
    setInitialUsername(initialUsername);
    setPathFromSearch(pathFromSearch);

    const queryParams = {
      action: "membergamelist",
      memberid: memberId,
    };

    dispatch(actions.callMemberGameList(queryParams)).then((resp) => {
      resp = resp.data;
      const gamesWithNoBalance = resp.memberGameLists.filter(
        (game) => game.gamebalance === "--"
      );
      if (gamesWithNoBalance.length > 0) {
        gamesWithNoBalance.forEach((game) => {
          const Params = {
            action: "membertargetbalance",
            memberid: memberId,
            companycode: game.gamecode,
          };
          dispatch(actions.callMemberGameListBalance(Params)).then(
            (response) => {
              setGameBalances((prevBalances) => ({
                ...prevBalances,
                [game.gamecode]: response.data.balance,
              }));
            }
          );
        });
      }
    });
  }, [memberId]);

  const customCardClass =
    "rounded d-flex flex-column align-items-center justify-content-center py-3";

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <TransferDialog
        selMemberId={memberId}
        selCompanyName={selCompanyName}
        selBalance={selBalance}
        selCompanyCode={selCompanyCode}
        selGameRate={selGameRate}
        selGameUsername={selGameUsername}
        selGameUrl={selGameUrl}
        selGamePass={selGamePass}
      />
      <CreateGameIdDialog
        selMemberId={memberId}
        selCompanyCode={selCompanyCode}
        selGameExist={selGameExist}
      />
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", margin: "0 3vh" }}
      >
        <h4>{initialUsername}</h4>
        <button
          className="btn btn-primary"
          onClick={() => {
            if (pathFromSearch == "yes") {
              navigate("/searchByUsername");
            } else {
              navigate("/accountList");
            }
          }}
        >
          {intl.formatMessage({ id: "back" })}
        </button>
      </div>
      <div
        className="row px-1 mb-4 justify-content-between align-items-center"
        style={{ width: "95%", margin: "0 auto", paddingTop: "10px" }}
      >
        {memberState.memberGameLists &&
        memberState.memberGameLists.memberGameLists ? (
          memberState.memberGameLists.memberGameLists.length > 0 &&
          memberState.memberGameLists.memberGameLists.map((game, index) => (
            <div className="mb-3 mx-1 gamelistDiv" key={index}>
              <div
                className={`${customCardClass} cursor-pointer position-relative text-center`}
                data-bs-toggle="modal"
                data-bs-target={
                  game.gameexist === "false"
                    ? `#createIdModal`
                    : `#TransferModal`
                }
                onClick={() => {
                  setSelCompanyName(game.gamename);
                  setSelCompanyCode(game.gamecode);
                  setSelBalance(
                    gameBalances[game.gamecode] || game.gamebalance
                  );
                  setSelGameRate(game.gamerate);
                  setSelGameUsername(game.gameusername);
                  setSelGamePass(game.gamepassword);
                  setSelGameUrl(game.relateurl);
                  setGameExist(game.gameexist);
                }}
              >
                <img
                  className="position-absolute"
                  src={toAbsoluteUrl("image/transferIcon.png")}
                  style={{
                    width: "20px",
                    right: "0.01rem",
                    top: "0.5rem",
                  }}
                />
                <div className="mb-3 truncate">{game.gamename}</div>
                {game.gameexist === "false" ? (
                  <div className="fw-bold text-prim card-button-border">
                    {intl.formatMessage({ id: "create" })}
                  </div>
                ) : (
                  <div className="fw-bold text-prim">
                    {gameBalances[game.gamecode] || game.gamebalance}
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <Skeleton height="600px" className="w-100" />
        )}
      </div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
    </div>
  );
};
