import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./agentList.css";
import * as actions from "../redux/AgentAction";
import { Switch } from "antd";
import { EditAgentDialog } from "./EditAgentDialog";
import { Modal } from "../../common/Modal1";

export const AgentDetails = ({
  selAgentId,
  isAgentDetailsVisible,
  onClose,
}) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [oldNickname, setOldNickname] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "agentdetail",
      selagentid: selAgentId,
    };
    dispatch(actions.callAgentDetails(queryParams));
  }, [selAgentId]);

  const handleSwitchChange = (checked, agent) => {
    const updateQueryParams = {
      action: "updateagentstatus",
      selagentid: agent,
    };
    dispatch(actions.updateAgentStatus(updateQueryParams))
      .then(() => {
        const queryParams = {
          action: "agentdetail",
          selagentid: selAgentId,
        };
        dispatch(actions.callAgentDetails(queryParams));
      })
      .catch((error) => {
        console.error("Error updating Agent status:", error);
      });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="agentDetailsModal"
        title={intl.formatMessage({ id: "agentDetails" })}
        dismissBtnRef={dismissBtnRef}
        isVisible={isAgentDetailsVisible}
        onClose={onClose}
      >
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <EditAgentDialog
          setSwalProps={setSwalProps}
          agentId={selAgentId}
          oldNickname={oldNickname}
          isVisible={isEditModalVisible}
          onClose={() => setEditModalVisible(false)}
        />

        <div className="agentDetails-div">
          <div
            className="d-flex"
            style={{
              overflowX: "auto",
              margin: "10px auto",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <div className="agentDetails-container">
              <div className="agentDetails-header">
                <div className="agentDetails-cell">
                  {intl.formatMessage({ id: "username" })}
                </div>
                <div className="agentDetails-cell">
                  {intl.formatMessage({ id: "nickname" })}
                </div>
                <div className="agentDetails-cell">
                  {intl.formatMessage({ id: "status" })}
                </div>
                <div className="agentDetails-cell">
                  {intl.formatMessage({ id: "balance" })}
                </div>
                <div className="agentDetails-cell">
                  {intl.formatMessage({ id: "totalAgent" })}
                </div>
                <div className="agentDetails-cell">
                  {intl.formatMessage({ id: "totalMember" })}
                </div>
              </div>
              <div className="agentDetails-middle">
                <div className="agentDetails-cell">:</div>
                <div className="agentDetails-cell">:</div>
                <div className="agentDetails-cell">:</div>
                <div className="agentDetails-cell">:</div>
                <div className="agentDetails-cell">:</div>
                <div className="agentDetails-cell">:</div>
              </div>
              <div className="agentDetails-body">
                {agentState && agentState.callAgentDetails ? (
                  <div className="agentDetails-row">
                    <div className="agentDetails-cell">
                      {agentState.callAgentDetails.username}
                    </div>
                    <div className="agentDetails-cell">
                      {agentState.callAgentDetails.nickname}
                    </div>
                    <div className="agentDetails-cell">
                      <Switch
                        checked={
                          agentState.callAgentDetails.status === "active"
                        }
                        onChange={(checked) =>
                          handleSwitchChange(
                            checked,
                            agentState.callAgentDetails.agentid
                          )
                        }
                      />
                    </div>
                    <div className="agentDetails-cell">
                      {agentState.callAgentDetails.balance}
                    </div>
                    <div className="agentDetails-cell">
                      {agentState.callAgentDetails.totalagentdownline}
                    </div>
                    <div className="agentDetails-cell">
                      {agentState.callAgentDetails.totalmember}
                    </div>
                  </div>
                ) : (
                  <Skeleton height="600px" className="w-100" />
                )}
              </div>
            </div>
          </div>
          <div className="editButton p-3">
            <button
              className="btn btn-primary px-3"
              onClick={() => {
                setOldNickname(agentState.callAgentDetails.nickname);
                setEditModalVisible(true);
              }}
            >
              {intl.formatMessage({ id: "edit" })}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
