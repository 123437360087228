import { useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./subAgent.css";
import * as actions from "../redux/SubAgentAction";
import { EditSubAgentDialog } from "./EditSubAgentDialog";
import { Switch } from "antd";
import { UpdateSubAgentPermission } from "./UpdateSubAgentPermission";

export const SubAgentList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [agentId, setAgentId] = useState(null);
  const [oldNickname, setOldNickname] = useState(null);
  // const [toggleSwitch, setToggleSwitch] = useState(false);
  const dispatch = useDispatch();
  const subAgentState = useSelector(({ subAgent }) => subAgent, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "subagentlist",
    };
    dispatch(actions.callSubAgentList(queryParams));
  }, []);

  const handleSwitchChange = (checked, agent) => {
    const updateQueryParams = {
      action: "updatesubagentstatus",
      selagentid: agent,
    };
    dispatch(actions.updateSubAgentStatus(updateQueryParams))
      .then(() => {
        const fetchQueryParams = {
          action: "subagentlist",
        };
        dispatch(actions.callSubAgentList(fetchQueryParams));
      })
      .catch((error) => {
        console.error("Error updating sub-agent status:", error);
      });
  };
  return (
    <div style={{ width: "95%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <EditSubAgentDialog
        setSwalProps={setSwalProps}
        agentId={agentId}
        oldNickname={oldNickname}
      />
      <UpdateSubAgentPermission setSwalProps={setSwalProps} agentId={agentId} />
      <div
        className="buttonCss"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {intl.formatMessage({ id: "addSubAgent" })}
        <button
          className="btn btn-primary buttonAdd"
          data-bs-toggle="modal"
          data-bs-target={`#addSubAgentModal`}
        >
          {intl.formatMessage({ id: "addSubAgent" })}
        </button>
      </div>
      <div
        className="d-flex"
        style={{
          overflowX: "auto",
          margin: "10px auto",
        }}
      >
        <div className="subagent-container">
          <div className="subagent-header">
            <div className="subagent-cell" style={{ flex: "1" }}>
              {intl.formatMessage({ id: "no" })}
            </div>
            <div className="subagent-cell" style={{ flex: "3" }}>
              {intl.formatMessage({ id: "username" })}
            </div>
            <div className="subagent-cell" style={{ flex: "3" }}>
              {intl.formatMessage({ id: "nickname" })}
            </div>
            <div className="subagent-cell" style={{ flex: "2" }}>
              {intl.formatMessage({ id: "status" })}
            </div>
            <div className="subagent-cell" style={{ flex: "2" }}>
              {intl.formatMessage({ id: "action" })}
            </div>
          </div>
          <div className="subagent-body">
            {subAgentState.subAgentLists &&
            subAgentState.subAgentLists.subAgentLists ? (
              subAgentState.subAgentLists.subAgentLists.length > 0 ? (
                subAgentState.subAgentLists.subAgentLists.map(
                  (agent, index) => (
                    <div key={index} className="subagent-row">
                      <div className="subagent-cell" style={{ flex: "1" }}>
                        {index + 1}
                      </div>
                      <div className="subagent-cell" style={{ flex: "3" }}>
                        {agent.username}
                      </div>
                      <div className="subagent-cell" style={{ flex: "3" }}>
                        {agent.nickname}
                      </div>
                      <div className="subagent-cell" style={{ flex: "2" }}>
                        {/* {agent.status === "suspended" ? (
                          agent.status
                        ) : (
                          <Switch
                            checked={agent.status === "active"}
                            onChange={(checked) =>
                              handleSwitchChange(checked, agent.agentid)
                            }
                          />
                        )} */}
                        <Switch
                          checked={agent.status === "active"}
                          onChange={(checked) =>
                            handleSwitchChange(checked, agent.agentid)
                          }
                        />
                      </div>
                      <div className="subagent-cell" style={{ flex: "2" }}>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target={`#editSubAgentModal`}
                          style={{ marginRight: "3px" }}
                          onClick={() => {
                            setAgentId(agent.agentid);
                            setOldNickname(agent.nickname);
                          }}
                        >
                          <i class="bi bi-pen"></i>
                        </button>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target={`#updateSubAgentPermissionModal`}
                          style={{ marginLeft: "3px" }}
                          onClick={() => {
                            setAgentId(agent.agentid);
                          }}
                        >
                          <i class="bi bi-gear"></i>
                        </button>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className="d-flex py-2 align-items-center justify-content-center flex-column oRecordDiv">
                  <i className="bi bi-search fs-1 mb-3" />
                  <div className="text-font2">
                    {intl.formatMessage({ id: "noRecordFound" })}
                  </div>
                </div>
              )
            ) : (
              <Skeleton height="600px" className="w-100" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
