/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { LoginRegisterDialog } from "../../modules/auth/components/LoginRegisterDialog";

const AuthWrapper = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/login" element={<LoginRegisterDialog />} />
    </Route>
  </Routes>
);

export { AuthWrapper };
