import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const subAgentState = {
  subAgentLists: null,
  addSubAgentLists: null,
  editSubAgentLists: null,
  updateSubAgentStatus: null,
  subAgentPermissionList: null,
  updateSubAgentPermission: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const subAgentSlice = createSlice({
  name: "subAgent",
  initialState: subAgentState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },

    subAgentListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.subAgentLists = action.payload.data ?? {};
    },
    addSubAgentListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.addSubAgentLists = action.payload.data ?? {};
    },
    editSubAgentListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.editSubAgentLists = action.payload.data ?? {};
    },
    updateSubAgentStatusFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateSubAgentStatus = action.payload.data ?? {};
    },
    subAgentPermissionListFetch: (state, action) => {
      state.actionsLoading = false;
      state.subAgentPermissionList = action.payload.data ?? {};
    },
    updateSubAgentPermissionFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateSubAgentPermission = action.payload.data ?? {};
    },
  },
});
