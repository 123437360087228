import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { SubAgentList } from "../../modules/subagentlist/components/SubAgent";

const SubAgentContext = createContext();

const SubAgentWrapper = () => {
  const value = {};

  return (
    <SubAgentContext.Provider value={value}>
      <Routes>
        <Route path="" element={<SubAgentList />} />
      </Routes>
    </SubAgentContext.Provider>
  );
};

export { SubAgentWrapper };
