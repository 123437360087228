import axios from "axios";

const baseUrl = "http://35.240.204.237/es3KioskApi";

export const LOGIN_URL = `${baseUrl}/login`;

// export const login = (queryParams) => {
//   return axios
//     .post(`${baseUrl}/Agent1.ashx`, queryParams)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       if (error.response) {
//         return error.response;
//       } else {
//         return error;
//       }
//     });
// };
export const login = (queryParams) => {
  return axios
    .post(
      `${window.location.protocol}//es3-agent.intechdecisions.com/api/index.php`,
      {
        url: `${baseUrl}/Agent1.ashx`,
        data: queryParams,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const getUserData = () => {
  const data = localStorage.getItem("UserData");
  if (!data) {
    return;
  }

  try {
    const auth = JSON.parse(data);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};
