import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { SearchByUsername } from "../../modules/searchByUsername/components/SearchByUsername";

const SearchContext = createContext();

const SearchWrapper = () => {
  const value = {};

  return (
    <SearchContext.Provider value={value}>
      <Routes>
        <Route path="" element={<SearchByUsername />} />
      </Routes>
    </SearchContext.Provider>
  );
};

export { SearchWrapper };
