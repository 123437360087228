import { useIntl } from "react-intl";
import React, { createContext, useEffect, useState } from "react";
import SideBar from "../slidebar/components/SideBar";
import "./Masterpage.css";
import Header from "../main/components/Header";
import { Outlet, useLocation } from "react-router-dom";
import PageTitle from "../main/components/PageTitle";
import "../main/components/main.css";
const MasterLayoutContext = createContext();

export const MasterLayout = () => {
  const intl = useIntl();
  const value = {};
  const location = useLocation();
  const path = location.pathname;
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    if (path.includes("/changePassword")) {
      setPageTitle("Change Password");
    } else if (path.includes("/profile")) {
      setPageTitle("Profile");
    } else if (path.includes("/companyWinLose")) {
      setPageTitle("Company Win/Lose");
    } else if (path.includes("/agentDownlineReport")) {
      setPageTitle("Agent Downline Report");
    } else if (path.includes("/transactionReport")) {
      setPageTitle("Transaction Report");
    } else if (path.includes("/lotteryPayout")) {
      setPageTitle("Lottery Payout");
    } else if (path.includes("/accountList")) {
      setPageTitle("Account List");
    } else if (path.includes("/agentList")) {
      setPageTitle("Agent Details");
    } else if (path.includes("/gameList")) {
      setPageTitle("Game List");
    } else if (path.includes("/memberReport")) {
      setPageTitle("Member Report");
    } else if (path.includes("/memberList")) {
      setPageTitle("Member Details");
    } else if (path.includes("/agentList")) {
      setPageTitle("Account List");
    } else if (path.includes("/subAgentList")) {
      setPageTitle("Sub-Agent List");
    } else if (path.includes("/searchByUsername")) {
      setPageTitle("Search Username");
    } else if (path.includes("/currencyExchange")) {
      setPageTitle("Currency Exchange");
    } else if (path.includes("/gameActiveList")) {
      setPageTitle("Game Active List");
    } else if (path.includes("/agentReport")) {
      setPageTitle("Financial Report");
    } else if (path.includes("/home")) {
      setPageTitle("Search Username");
    } else {
      setPageTitle("Dashboard");
    }
  }, [path]);

  return (
    <MasterLayoutContext.Provider value={value}>
      <div style={{ height: "8vh", width: "100%" }}>
        <Header />
      </div>
      <div style={{ width: "100%" }}>
        <SideBar />
        <div id="main" className="main">
          <div className="top-item">
            <PageTitle page={path} pageTitle={pageTitle} />
          </div>
          <Outlet />
        </div>
      </div>
    </MasterLayoutContext.Provider>
  );
};
