import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/ReportAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export const AgentDownlineDetailsList = () => {
  const { selagentID } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(new Date(location.state?.fromDate));
  const [toDate, setToDate] = useState(new Date(location.state?.toDate));
  const reportState = useSelector(({ report }) => report, shallowEqual);
  const timeZone = "Asia/Kuala_Lumpur";

  useEffect(() => {
    const fromDateStr = location.state?.fromDate;
    const toDateStr = location.state?.toDate;

    if (fromDateStr && toDateStr) {
      const fromDateParsed = new Date(fromDateStr);
      const toDateParsed = new Date(toDateStr);

      if (!isNaN(fromDateParsed) && !isNaN(toDateParsed)) {
        setFromDate(fromDateParsed);
        setToDate(toDateParsed);
        const queryParams = {
          action: "agentfcgamereport",
          fromdate: formatInTimeZone(fromDateParsed, timeZone, "yyyy-MM-dd"),
          todate: formatInTimeZone(toDateParsed, timeZone, "yyyy-MM-dd"),
          selagentid: selagentID,
        };
        dispatch(actions.agentDownlineDetails(queryParams));
      } else {
        console.error("Invalid date format received:", {
          fromDateStr,
          toDateStr,
        });
      }
    }
  }, [selagentID, location.state]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
    const queryParams = {
      action: "agentfcgamereport",
      fromdate: formatInTimeZone(start, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(end, timeZone, "yyyy-MM-dd"),
      selagentid: selagentID,
    };

    dispatch(actions.agentDownlineDetails(queryParams));
  };

  const customCardClass =
    "rounded d-flex flex-column align-items-center justify-content-center py-3";

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />
      <div style={{ margin: "5px 0", textDecoration: "underline" }}></div>
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div>
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <button
          className="btn btn-primary"
          style={{ maxHeight: "40px" }}
          onClick={() => {
            navigate("/agentReport/agentDownlineReport");
          }}
        >
          {intl.formatMessage({ id: "back" })}
        </button>
      </div>

      <div className="member-div d-flex" style={{ margin: "10px auto" }}>
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-3">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "totalBet" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "totalWin" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "totalProfit" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {reportState.agentDownlineDetailsLists &&
                  reportState.agentDownlineDetailsLists.agentFcCompanies &&
                  reportState.agentDownlineDetailsLists.agentFcCompanies
                    .length > 0 &&
                  reportState.agentDownlineDetailsLists.agentFcCompanies.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-3">
                          {report.companycode}
                        </td>
                        <td className="member-cell col-2">{report.totalbet}</td>
                        <td className="member-cell col-2">{report.totalwin}</td>
                        <td className="member-cell col-2">
                          {report.totalprofit}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
              <tfoot>
                {reportState && reportState.agentDownlineDetailsLists && (
                  <tr className="member-row1">
                    <td className="member-cell hide-on-mobile col-1"></td>
                    <td
                      className="member-cell col-3"
                      style={{ textAlign: "end" }}
                    >
                      {intl.formatMessage({ id: "total" }) + " : "}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.agentDownlineDetailsLists.totalbet}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.agentDownlineDetailsLists.totalwin}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.agentDownlineDetailsLists.totalprofit}
                    </td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
