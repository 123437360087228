import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

export function checkAgentList(params) {
  return authPost(`${baseUrl}/Agent2.ashx`, params);
}
export function checkAgentDetails(params) {
  return authPost(`${baseUrl}/Agent3.ashx`, params);
}
