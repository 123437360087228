export const LanguageLocale = {
  EN: "en",
  ZH: "zh",
  MS: "ms",
  ID: "id",
};

export const LanguageTypeConst = {
  0: "en",
  1: "zh",
  2: "ms",
  3: "id",
};

export const LanguageDDLConst = [
  {
    language: LanguageLocale.EN,
    title: "english",
  },
  {
    language: LanguageLocale.ZH,
    title: "chinese",
  },
  {
    language: LanguageLocale.MS,
    title: "malay",
  },
  {
    language: LanguageLocale.ID,
    title: "indonesia",
  },
];
