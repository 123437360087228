import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { ChangePasswordDialog } from "../../modules/changePassword/components/ChangePasswordDialog";

const ChangePasswordContext = createContext();

const ChangePasswordWrapper = () => {
  const value = {};

  return (
    <ChangePasswordContext.Provider value={value}>
      <Routes>
        <Route path="" element={<ChangePasswordDialog />} />
      </Routes>
    </ChangePasswordContext.Provider>
  );
};

export { ChangePasswordWrapper };
