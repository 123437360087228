import { useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/SearchAction";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import "../../agentlist/components/agentList.css";
import { TopupAndWithdrawDialog } from "../../agentlist/components/TopupAndWithdrawDialog";
import { AgentDetails } from "../../agentlist/components/AgentDetails";
import { AgentGameList } from "../../agentlist/components/AgentGameList";
import { MemberList } from "../../memberlist/components/MemberList";

export const SearchByUsername = () => {
  const intl = useIntl();
  const [searchName, setSearchName] = useState("");
  const [swalProps, setSwalProps] = useState({});
  const [showList, setShowList] = useState(false);
  const [selAgentId, setSelAgentId] = useState(null);
  const [selMemberId, setSelMemberId] = useState(null);
  const [selUsername, setSelUsername] = useState(null);
  const [selNickname, setSelNickname] = useState(null);
  const [selBalance, setSelBalance] = useState(null);
  const [agentDetailsVisible, setAgentDetailsVisible] = useState(false);
  const [memberDetailsVisible, setMemberDetailsVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchState = useSelector(({ search }) => search, shallowEqual);

  const handleSearch = () => {
    setSearchName("");
    const queryParams = {
      action: "searchbyusername",
      searchusername: searchName,
    };
    dispatch(actions.callSearchList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      } else {
        setShowList(true);
      }
    });
  };

  const renderSearchResults = (list, type) => (
    <>
      <div className="buttonCss">{intl.formatMessage({ id: type })}</div>
      <div className="agent-container" style={{ overflowX: "auto" }}>
        <table className="agent-table">
          <thead className="agent-header">
            <tr className="agent-row">
              <th className="agent-cell hide-on-mobile col-1">
                {intl.formatMessage({ id: "no" })}
              </th>
              <th className="agent-cell col-3 fixed-column">
                {intl.formatMessage({ id: "username" })}
              </th>
              <th className="agent-cell col-3">
                {intl.formatMessage({ id: "nickname" })}
              </th>
              <th className="agent-cell col-2">
                {intl.formatMessage({ id: "status" })}
              </th>
              <th className="agent-cell col-2">
                {intl.formatMessage({ id: "action" })}
              </th>
            </tr>
          </thead>
          <tbody className="agent-body">
            {list.length > 0 ? (
              list.map((item, index) => (
                <tr key={index} className="agent-row">
                  <td className="agent-cell hide-on-mobile col-1">
                    {index + 1}
                  </td>
                  <td className="agent-cell col-3 fixed-column">
                    <button
                      className="buttonName"
                      onClick={() => {
                        navigate(`/accountList`, {
                          state: {
                            username: item.username,
                            agentId: item.agentid,
                          },
                        });
                      }}
                    >
                      {item.username}
                    </button>
                  </td>
                  <td className="agent-cell col-3">{item.nickname}</td>
                  <td className="agent-cell col-2">{item.status}</td>
                  <td className="agent-cell col-2 d-flex">
                    {type === "agentList" ? (
                      <>
                        <button
                          className="btn btn-primary me-1"
                          onClick={() => {
                            setSelAgentId(item.agentid);
                            setAgentDetailsVisible(true);
                          }}
                        >
                          <i className="bi bi-exclamation-circle" />
                        </button>
                        <button
                          className="btn btn-primary me-1"
                          data-bs-toggle="modal"
                          data-bs-target="#TopupAndWithdrawModal"
                          onClick={() => {
                            setSelAgentId(item.agentid);
                            setSelUsername(item.username);
                            setSelNickname(item.nickname);
                            setSelBalance(item.balance);
                          }}
                        >
                          <i className="bi bi-wallet2" />
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setSelAgentId(item.agentid);
                          }}
                        >
                          <i className="bi bi-list-ul" />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary me-1"
                          onClick={() => {
                            setSelMemberId(item.memberid);
                            setMemberDetailsVisible(true);
                          }}
                        >
                          <i className="bi bi-exclamation-circle" />
                        </button>
                        <button
                          className="btn btn-primary me-1"
                          onClick={() =>
                            navigate(`/memberList/gameList/${item.memberid}`, {
                              state: {
                                userName: item.username,
                                searchName: "yes",
                              },
                            })
                          }
                        >
                          <i className="bi bi-list-ul" />
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            navigate(
                              `/memberList/memberReport/${item.memberid}`,
                              {
                                state: {
                                  searchName: "yes",
                                },
                              }
                            )
                          }
                        >
                          <i className="bi bi-clipboard2-data" />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="oRecordDiv">
                <td colSpan="5">
                  <div className="no-record-wrapper">
                    <i className="bi bi-search fs-1 mb-3" />
                    <div className="text-font2">
                      {intl.formatMessage({ id: "noRecordFound" })}
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <div style={{ width: "95%", margin: "5px auto" }}>
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        backdrop={false}
      />
      <TopupAndWithdrawDialog
        setSwalProps={setSwalProps}
        selAgentId={selAgentId}
        selUsername={selUsername}
        selNickname={selNickname}
        selBalance={selBalance}
      />
      <AgentDetails
        selAgentId={selAgentId}
        isAgentDetailsVisible={agentDetailsVisible}
        onClose={() => setAgentDetailsVisible(false)}
      />
      <AgentGameList selAgentId={selAgentId} />
      <MemberList
        selMemberId={selMemberId}
        isMemberDetailsVisible={memberDetailsVisible}
        onClose={() => setMemberDetailsVisible(false)}
      />
      <div className="d-flex">
        <div style={{ width: "80%", marginRight: "5px" }}>
          <input
            name="searchName"
            type="text"
            className="form-control"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder={intl.formatMessage({ id: "username" })}
          />
        </div>
        <div>
          <button className="btn btn-primary" onClick={handleSearch}>
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>
      {showList && (
        <div>
          {searchState.searchLists?.agentList?.agentLists ? (
            renderSearchResults(
              searchState.searchLists.agentList.agentLists,
              "agentList"
            )
          ) : (
            <Skeleton height="600px" className="w-100" />
          )}
          {searchState.searchLists?.memberList?.memberLists ? (
            renderSearchResults(
              searchState.searchLists.memberList.memberLists,
              "memberList"
            )
          ) : (
            <Skeleton height="600px" className="w-100" />
          )}
        </div>
      )}
    </div>
  );
};
