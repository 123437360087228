import { authFormPost, authPost, doPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

// start : profile

export function changePassword(params) {
  return authPost(`${baseUrl}/Agent1.ashx`, params);
}

// export function changeLanguage(params) {
//   return authPost(`${baseUrl}/Member2/ChangeLanguage`, params);
// }

// end : profile
