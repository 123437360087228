import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/ReportAction";
import { useNavigate } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export const AgentReportList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const reportState = useSelector(({ report }) => report, shallowEqual);

  useEffect(() => {
    const timeZone = "Asia/Kuala_Lumpur";
    const queryParams = {
      action: "agentcompanyreport",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callAgentReportList(queryParams));
  }, [toDate]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
    const timeZone = "Asia/Kuala_Lumpur";
    const queryParams = {
      action: "agentcompanyreport",
      fromdate: formatInTimeZone(start, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(end, timeZone, "yyyy-MM-dd"),
    };

    dispatch(actions.callAgentReportList(queryParams));
  };

  const customCardClass =
    "rounded d-flex align-items-center justify-content-spacebetween py-2";

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div>
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      <div>
        {reportState && reportState.agentReportLists && (
          <div
            className={`${customCardClass} gamelistDiv1 cursor-pointer position-relative text-center`}
            style={{
              justifyContent: "space-between",
              border: "1px solid grey",
              padding: "5px",
              margin: "10px 0 ",
            }}
          >
            <div>
              {intl.formatMessage({ id: "totalTopup" }) +
                " : " +
                reportState.agentReportLists.totaltopup}
            </div>
            <div>
              {intl.formatMessage({ id: "totalWithdraw" }) +
                " : " +
                reportState.agentReportLists.totalwithdraw}
            </div>
          </div>
        )}
      </div>

      {/* <div>
          {reportState && reportState.agentReportLists && (
            <div>
              <div>
                {intl.formatMessage({ id: "totalBet" }) +
                  " : " +
                  reportState.agentReportLists.totalbet}
              </div>
              <div>
                {intl.formatMessage({ id: "totalWin" }) +
                  " : " +
                  reportState.agentReportLists.totalwin}
              </div>
              <div>
                {intl.formatMessage({ id: "totalProfit" }) +
                  " : " +
                  reportState.agentReportLists.totalprofit}
              </div>
            </div>
          )}
        </div> */}
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-3">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "totalBet" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "totalWin" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "totalProfit" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {reportState.agentReportLists &&
                  reportState.agentReportLists.agentCompanyReports &&
                  reportState.agentReportLists.agentCompanyReports.length > 0 &&
                  reportState.agentReportLists.agentCompanyReports.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-3">
                          {report.companycode}
                        </td>
                        <td className="member-cell col-2">{report.totalbet}</td>
                        <td className="member-cell col-2">{report.totalwin}</td>
                        <td className="member-cell col-2">
                          {report.totalprofit}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
              <tfoot>
                {reportState && reportState.agentReportLists && (
                  <tr className="member-row1">
                    <td className="member-cell hide-on-mobile col-1"></td>
                    <td
                      className="member-cell col-3"
                      style={{ textAlign: "end" }}
                    >
                      {intl.formatMessage({ id: "total" }) + " : "}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.agentReportLists.totalbet}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.agentReportLists.totalwin}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.agentReportLists.totalprofit}
                    </td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
