import { combineReducers } from "redux";
import { dashboardSlice } from "../modules/dashboard/redux/DashboardSlice";
import { changePasswordSlice } from "../modules/changePassword/redux/ChangePasswordSlice";
import { mainSlice } from "../modules/main/redux/MainSlice";
import { subAgentSlice } from "../modules/subagentlist/redux/SubAgentSlice";
import { agentSlice } from "../modules/agentlist/redux/AgentSlice";
import { memberSlice } from "../modules/memberlist/redux/MemberSlice";
import { searchSlice } from "../modules/searchByUsername/redux/SearchSlice";
import { platformSettingSlice } from "../modules/platformSetting/redux/PlatfromSettingSlice";
import { reportSlice } from "../modules/report/redux/ReportSlice";

export const rootReducer = combineReducers({
  dashboard: dashboardSlice.reducer,
  changePassword: changePasswordSlice.reducer,
  main: mainSlice.reducer,
  subAgent: subAgentSlice.reducer,
  agent: agentSlice.reducer,
  member: memberSlice.reducer,
  search: searchSlice.reducer,
  platformSetting: platformSettingSlice.reducer,
  report: reportSlice.reducer,
});
