import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./MemberReport.css";
import * as actions from "../redux/MemberAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays } from "date-fns";

export const MemberReportList = () => {
  const { memberId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const [pathFromSearch, setPathFromSearch] = useState("");
  const memberState = useSelector(({ member }) => member, shallowEqual);
  const location = useLocation();

  useEffect(() => {
    const pathFromSearch = location.state?.searchName;
    setPathFromSearch(pathFromSearch);
    const queryParams = {
      action: "memberes3report",
      memberid: memberId,
      pagenum: "0",
      fromdate: fromDate.toISOString().split("T")[0],
      todate: toDate.toISOString().split("T")[0],
    };
    dispatch(actions.callMemberReportList(queryParams));
  }, [toDate, memberId, dispatch]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
    const queryParams = {
      action: "memberes3report",
      memberid: memberId,
      pagenum: "0",
      fromdate: start.toISOString().split("T")[0],
      todate: end.toISOString().split("T")[0],
    };
    dispatch(actions.callMemberReportList(queryParams));
  };

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div>
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <button
          className="btn btn-primary"
          style={{ maxHeight: "40px" }}
          onClick={() => {
            if (pathFromSearch == "yes") {
              navigate("/searchByUsername");
            } else {
              navigate("/accountList");
            }
          }}
        >
          {intl.formatMessage({ id: "back" })}
        </button>
      </div>

      <div
        className="member-div"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-3">
                    {intl.formatMessage({ id: "date" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "beforeAmount" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "amount" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "afterAmount" })}
                  </th>
                  <th className="member-cell col-3">
                    {intl.formatMessage({ id: "action" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {memberState.memberReportLists?.memberEs3Reports ? (
                  memberState.memberReportLists.memberEs3Reports.length > 0 ? (
                    memberState.memberReportLists.memberEs3Reports.map(
                      (member, index) => (
                        <tr key={index} className="member-row">
                          <td className="member-cell hide-on-mobile col-1">
                            {index + 1}
                          </td>
                          <td className="member-cell col-3">
                            {member.reportdate}
                          </td>
                          <td className="member-cell col-2">
                            {member.bfamount}
                          </td>
                          <td className="member-cell col-2">{member.amount}</td>
                          <td className="member-cell col-2">
                            {member.afamount}
                          </td>
                          <td className="member-cell col-3">
                            {member.message}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr className="d-flex py-2 align-items-center justify-content-center flex-column oRecordDiv">
                      <td colSpan="6">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="6">
                      <Skeleton height="600px" className="w-100" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
