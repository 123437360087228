import { useIntl } from "react-intl";
import { EditPasswordForm } from "./ChangePasswordForm";
import { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/ChangePasswordAction";
import SweetAlert2 from "react-sweetalert2";
import "./ChangePassword.css";
export const ChangePasswordDialog = () => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [swalProps, setSwalProps] = useState({});
  const changePasswordState = useSelector(
    ({ changePassword }) => changePassword,
    shallowEqual
  );

  const saveValues = (queryParams) => {
    dispatch(actions.updatePassword(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === 0) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "changePasswordSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  // useEffect(() => {
  //   console.log("123");
  // }, []);
  return (
    <div className="changePasswordDiv">
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
      />
      <div style={{ textAlign: "center" }}>
        <h3>{intl.formatMessage({ id: "changePassword" })}</h3>
      </div>
      <div className="p-3 custom-border-bottom">
        <EditPasswordForm formikRef={formikRef} saveValues={saveValues} />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        {changePasswordState.actionsLoading && (
          <div
            className="spinner-border me-2"
            role="status"
            style={{ scale: "0.55" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          type="button"
          disabled={changePasswordState.actionsLoading}
          className="btn btn-primary fw-bold px-3"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage({ id: "submit" })}
        </button>
      </div>
    </div>
  );
};
