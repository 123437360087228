import "./Modal.css";

export const Modal = ({ modalId, children, title, dismissBtnRef }) => {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ margin: "0 auto" }}
      >
        <div className="modal-content modalBackground">
          <div className="d-flex align-items-center justify-content-between custom-border-bottom p-3">
            <h1
              className="modal-title text-font4"
              id="exampleModalLabel"
              style={{ width: "100%" }}
            >
              {title}
            </h1>
            <i
              className="bi bi-x modal-close cursor-pointer fs-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={dismissBtnRef}
            />
          </div>
          <div className="modal-body p-0 text-font3">{children}</div>
        </div>
      </div>
    </div>
  );
};
