import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import { changePasswordSlice } from "./ChangePasswordSlice";
import * as requestToApi from "./ChangePasswordCRUD";

const { actions } = changePasswordSlice;

// start : profile

export const updatePassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .changePassword(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.editPasswordFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: changePassword");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

// export const updateLanguage = (queryParams) => (dispatch) => {
//   dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

//   return requestToApi
//     .changeLanguage(queryParams)
//     .then((response) => {
//       if (response) {
//         if (!response.data.isAbort) {
//           dispatch(actions.editLanguage({ data: response.data }));
//           return response;
//         }
//       } else {
//         throw new Error("Error api: changeLanguage");
//       }
//     })
//     .catch((error) => {
//       dispatch(
//         actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
//       );
//       throw error;
//     });
// };

// end : profile
