import { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import { Route, Routes, Navigate } from "react-router-dom";
import { getDateLocale } from "../utils/DateLocale";
import { React } from "react";
import { MasterLayout } from "../modules/common/Masterpage";
import { DashbordWrapper } from "../pages/dashboard/DashboardWrapper";
import { ChangePasswordWrapper } from "../pages/changePassword/ChangePasswordWrapper";
import { SubAgentWrapper } from "../pages/subagentlist/SubAgentWrapper";
import { AgentWrapper } from "../pages/agentlist/AgentListWrapper";
import { MemberWrapper } from "../pages/memberlist/MemberListWrapper";
import { SearchWrapper } from "../pages/searchByUsername/SearchWrapper";
import { AgentReportWrapper } from "../pages/report/ReportWrapper";
import { PlatformSettingWrapper } from "../pages/platformSetting/PlatformSettingWrapper";

const PrivateRoutes = () => {
  const intl = useIntl();
  let locale = intl.locale;
  registerLocale(locale, getDateLocale(locale));

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="home/*" element={<SearchWrapper />} />
          <Route path="changePassword/*" element={<ChangePasswordWrapper />} />
          <Route path="memberList/*" element={<MemberWrapper />} />
          <Route path="accountList/*" element={<AgentWrapper />} />
          <Route path="subAgentList/*" element={<SubAgentWrapper />} />
          <Route path="searchByUsername/*" element={<SearchWrapper />} />
          <Route path="gameActiveList/*" element={<PlatformSettingWrapper />} />
          <Route path="agentReport/*" element={<AgentReportWrapper />} />
          <Route path="*" element={<Navigate to={"/home"} />} />
        </Route>
      </Routes>
    </>
  );
};

export { PrivateRoutes };
