import React, { useEffect, useState } from "react";
import "./nav.css";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/MainAction";

function Nav() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const mainState = useSelector(({ main }) => main, shallowEqual);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const queryParams = {
      action: "groupcredit",
    };
    dispatch(actions.callGroupCredit(queryParams));
  }, []);

  const balance = mainState.groupCredit?.balance ?? "0.00";

  const handleRefreshData = () => {
    setIsLoading(true);

    const params = {
      action: "groupcredit",
    };

    dispatch(actions.callGroupCredit(params));

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  return (
    <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">
        <div className="d-flex">
          <div>{intl.formatMessage({ id: "balance" })}</div>
          <div style={{ margin: "0 2px" }}>{":"}</div>
          <div style={{ marginRight: "5px" }}>{isLoading ? "-" : balance}</div>
        </div>
        {isLoading ? (
          <div style={{ width: "20px", height: "20px" }} />
        ) : (
          <i
            className="bi bi-arrow-clockwise"
            style={{ marginRight: "5px" }}
            onClick={handleRefreshData}
          />
        )}
      </ul>
    </nav>
  );
}

export default Nav;
