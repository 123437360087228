import { useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./agentList.css";
import { useAuth } from "../../auth/components/AuthInit";
import * as actions from "../redux/AgentAction";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateMemberDialog } from "../../memberlist/components/CreateMemberDialog";
import { TopupAndWithdrawDialog } from "./TopupAndWithdrawDialog";
import { CreateAgentDialog } from "./CreateAgentDialog";
import { Link } from "react-router-dom";
import { AgentGameList } from "./AgentGameList";
import { AgentDetails } from "./AgentDetails";
import { MemberList } from "../../memberlist/components/MemberList";

export const AgentList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [selAgentId, setSelAgentId] = useState(null);
  const [selMemberId, setSelMemberId] = useState(null);
  const [selUsername, setSelUsername] = useState(null);
  const [selNickname, setSelNickname] = useState(null);
  const [selBalance, setSelBalance] = useState(null);
  const [topUpLine, setTopUpLine] = useState(null);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [agentDetailsVisible, setAgentDetailsVisible] = useState(false);
  const [memberDetailsVisible, setMemberDetailsVisible] = useState(false);

  const dispatch = useDispatch();
  const { auth, saveAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    const initialAgentId = location.state?.agentId || auth.agentid;
    const initialUsername = location.state?.username || auth.username;
    setCurrentAgent(initialUsername);
    const queryParams = {
      action: "agentmemberlist",
      selagentid: initialAgentId,
      agentpagenum: "0",
      memberpagenum: "0",
    };
    dispatch(actions.callAgentList(queryParams)).then((resp) => {
      resp = resp.data;
      setTopUpLine(resp.agentUplineLists);
    });
  }, [location.state, auth.agentid, auth.username, dispatch]);

  const handleClick = (agentId, username) => {
    setCurrentAgent(username);
    const queryParams = {
      action: "agentmemberlist",
      selagentid: agentId,
      agentpagenum: "0",
      memberpagenum: "0",
    };

    dispatch(actions.callAgentList(queryParams))
      .then((resp) => {
        resp = resp.data;
        setTopUpLine(resp.agentUplineLists);
      })
      .catch((error) => {
        console.error("Error fetching agent list:", error);
      });
  };

  return (
    <div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <CreateMemberDialog setSwalProps={setSwalProps} />
      <CreateAgentDialog setSwalProps={setSwalProps} />
      <AgentGameList selAgentId={selAgentId} />
      <AgentDetails
        selAgentId={selAgentId}
        isAgentDetailsVisible={agentDetailsVisible}
        onClose={() => setAgentDetailsVisible(false)}
      />
      <MemberList
        selMemberId={selMemberId}
        isMemberDetailsVisible={memberDetailsVisible}
        onClose={() => setMemberDetailsVisible(false)}
      />
      <TopupAndWithdrawDialog
        setSwalProps={setSwalProps}
        selAgentId={selAgentId}
        selUsername={selUsername}
        selNickname={selNickname}
        selBalance={selBalance}
      />
      <div
        className="buttonCss"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <nav>
            <ol className="breadcrumb">
              {topUpLine && topUpLine.length > 0 ? (
                <>
                  <li>
                    {topUpLine
                      .slice()
                      .reverse()
                      .map((agent, index) => (
                        <>
                          <span
                            key={index}
                            onClick={() =>
                              handleClick(agent.agentid, agent.username)
                            }
                          >
                            {agent.username}
                          </span>
                        </>
                      ))}
                  </li>
                </>
              ) : (
                <li>
                  <span onClick={() => handleClick(auth.agentid)}>
                    {auth.username}
                  </span>
                </li>
              )}
              {topUpLine && topUpLine.length > 0 && (
                <li>
                  <span className="breadcrumb-separator">/</span>
                  <span>{currentAgent}</span>
                </li>
              )}
            </ol>
          </nav>
        </div>

        <button
          className="btn btn-primary buttonAdd"
          data-bs-toggle="modal"
          data-bs-target={`#addAgentModal`}
        >
          {intl.formatMessage({ id: "addAgent" })}
        </button>
      </div>
      <div
        className="agent-div"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="agent-container" style={{ overflowX: "auto" }}>
          <table className="agent-table">
            <thead className="agent-header">
              <tr className="agent-row">
                <th className="agent-cell hide-on-mobile col-1">
                  {intl.formatMessage({ id: "no" })}
                </th>
                <th className="agent-cell col-3 fixed-column">
                  {intl.formatMessage({ id: "username" })}
                </th>
                <th className="agent-cell col-3">
                  {intl.formatMessage({ id: "nickname" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "status" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "action" })}
                </th>
              </tr>
            </thead>
            <tbody className="agent-body">
              {agentState.agentLists?.agentList?.agentLists ? (
                agentState.agentLists.agentList.agentLists.length > 0 ? (
                  agentState.agentLists.agentList.agentLists.map(
                    (agent, index) => (
                      <tr key={index} className="agent-row">
                        <td className="agent-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="agent-cell col-3 fixed-column">
                          <button
                            className="buttonName"
                            onClick={() =>
                              handleClick(agent.agentid, agent.username)
                            }
                          >
                            {agent.username}
                          </button>
                        </td>
                        <td className="agent-cell col-3">{agent.nickname}</td>
                        <td className="agent-cell col-2">{agent.status}</td>
                        <td className="agent-cell col-2 d-flex">
                          <button
                            className="btn btn-primary me-1"
                            onClick={() => {
                              setSelAgentId(agent.agentid);
                              setAgentDetailsVisible(true);
                            }}
                          >
                            <i className="bi bi-exclamation-circle" />
                          </button>
                          <button
                            className="btn btn-primary me-1"
                            data-bs-toggle="modal"
                            data-bs-target="#TopupAndWithdrawModal"
                            onClick={() => {
                              setSelAgentId(agent.agentid);
                              setSelUsername(agent.username);
                              setSelNickname(agent.nickname);
                              setSelBalance(agent.balance);
                            }}
                          >
                            <i className="bi bi-wallet2" />
                          </button>
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target={`#GameListModal`}
                            onClick={() => {
                              setSelAgentId(agent.agentid);
                            }}
                          >
                            <i className="bi bi-list-ul" />
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr className="oRecordDiv">
                    <td colSpan="5">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="5">
                    <Skeleton height="600px" className="w-100" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div
          className="buttonCss"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {intl.formatMessage({ id: "memberList" })}
          <button
            className="btn btn-primary buttonAdd"
            data-bs-toggle="modal"
            data-bs-target={`#addMemberModal`}
          >
            {intl.formatMessage({ id: "addMember" })}
          </button>
        </div>
        <div className="agent-container" style={{ overflowX: "auto" }}>
          <table className="agent-table" style={{ minWidth: "400px" }}>
            <thead className="agent-header">
              <tr className="agent-row">
                <th className="agent-cell hide-on-mobile col-1">
                  {intl.formatMessage({ id: "no" })}
                </th>
                <th className="agent-cell col-4 fixed-column">
                  {intl.formatMessage({ id: "username" })}
                </th>
                <th className="agent-cell col-5">
                  {intl.formatMessage({ id: "nickname" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "action" })}
                </th>
              </tr>
            </thead>
            <tbody className="agent-body">
              {agentState.agentLists &&
              agentState.agentLists.memberList &&
              agentState.agentLists.memberList.memberLists ? (
                agentState.agentLists.memberList.memberLists.length > 0 ? (
                  agentState.agentLists.memberList.memberLists.map(
                    (member, index) => (
                      <tr key={index} className="agent-row">
                        <td className="agent-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="agent-cell col-4 fixed-column">
                          {member.username}
                        </td>
                        <td className="agent-cell col-5">{member.nickname}</td>
                        <td className="agent-cell col-2 d-flex">
                          <button
                            className="btn btn-primary me-1"
                            style={{
                              backgroundColor: "#00FFFF",
                              borderColor: "#00FFFF",
                              color: "black",
                            }}
                            onClick={() => {
                              setSelMemberId(member.memberid);
                              setMemberDetailsVisible(true);
                            }}
                          >
                            <i className="bi bi-exclamation-circle" />
                          </button>
                          <button
                            className="btn btn-primary me-1"
                            style={{
                              backgroundColor: "#00FF00",
                              borderColor: "#00FF00",
                              color: "black",
                            }}
                            onClick={() =>
                              navigate(
                                `/memberList/gameList/${member.memberid}`,
                                {
                                  state: {
                                    userName: member.username,
                                  },
                                }
                              )
                            }
                          >
                            <i className="bi bi-controller" />
                          </button>
                          <button
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#FFFF00",
                              borderColor: "#FFFF00",
                              color: "black",
                            }}
                            onClick={() =>
                              navigate(
                                `/memberList/memberReport/${member.memberid}`
                              )
                            }
                          >
                            <i className="bi bi-clipboard2-data" />
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr className="oRecordDiv">
                    <td colSpan="5">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="5">
                    <Skeleton height="600px" className="w-100" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
