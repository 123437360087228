import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/SubAgentAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";
import Skeleton from "react-loading-skeleton";

export const UpdateSubAgentPermission = ({ setSwalProps, agentId }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const subAgentState = useSelector(({ subAgent }) => subAgent, shallowEqual);
  const [permissions, setPermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);

  useEffect(() => {
    const queryParams = {
      action: "subagentpermissionlist",
      selagentid: agentId,
    };
    dispatch(actions.subAgentPermissionList(queryParams));
  }, [agentId]);

  useEffect(() => {
    if (subAgentState.subAgentPermissionList?.subAgentPermissionLists) {
      setPermissions(
        subAgentState.subAgentPermissionList.subAgentPermissionLists
      );
      setInitialPermissions(
        subAgentState.subAgentPermissionList.subAgentPermissionLists
      );
    }
  }, [subAgentState.subAgentPermissionList]);

  const handleCheckBoxChange = (checked, index) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((agent, i) =>
        i === index ? { ...agent, allow: checked ? "true" : "false" } : agent
      )
    );
  };

  const handleSubmit = () => {
    const changedPermissions = permissions.filter(
      (permission, index) =>
        permission.allow !== initialPermissions[index].allow
    );

    changedPermissions.forEach((permission) => {
      const queryParams = {
        action: "updatesubagentpermission",
        selagentid: agentId,
        permissioncode: permission.permissioncode,
      };

      dispatch(actions.updateSubAgentPermission(queryParams)).then((resp) => {
        resp = resp.data;

        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "updateSubAgentSuccess" }),
            onConfirm: () => {
              dismissBtnRef.current.click();
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="updateSubAgentPermissionModal"
        title={intl.formatMessage({ id: "updateSubAgentPermission" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div>
          {subAgentState.subAgentPermissionList &&
          subAgentState.subAgentPermissionList.subAgentPermissionLists ? (
            permissions.map((agent, index) => (
              <div key={index} className="subAgentPermission">
                <input
                  type="checkbox"
                  checked={agent.allow === "true"}
                  onChange={(e) =>
                    handleCheckBoxChange(e.target.checked, index)
                  }
                  style={{
                    marginRight: "2vh",
                    width: "2vh",
                    height: "2vh",
                  }}
                />
                <span>{agent.description}</span>
              </div>
            ))
          ) : (
            <Skeleton height="600px" className="w-100" />
          )}
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          {subAgentState.actionsLoading && (
            <div
              className="spinner-border me-2"
              role="status"
              style={{ scale: "0.55" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <button
            type="button"
            disabled={subAgentState.actionsLoading}
            className="btn btn-primary px-3"
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: "update" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
