import { useIntl } from "react-intl";
import { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/AgentAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";
import { WithdrawForm } from "./WithdrawForm";
import { TopupForm } from "./TopupForm";
import { TopupAndWithdrawConst } from "../../../constants/TopUpAndWithdrawConst";

export const TopupAndWithdrawDialog = ({
  setSwalProps,
  selAgentId,
  selUsername,
  selNickname,
  selBalance,
}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [selectedNav, setSelectedNav] = useState(TopupAndWithdrawConst.TOPUP);
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  const saveValues = (queryParams) => {
    dispatch(actions.agentTopupAndWithdraw(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text:
            queryParams.action === "agenttopupagent"
              ? intl.formatMessage({ id: "topupSuccess" })
              : intl.formatMessage({ id: "withdrawSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            window.location.reload();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="TopupAndWithdrawModal"
        title={intl.formatMessage({ id: "topupAndWithdraw" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="d-flex">
          {Object.entries(TopupAndWithdrawConst).map(([key, value]) => (
            <h6
              style={{
                color: selectedNav === value ? "#0080FF" : "",
                borderBottom: selectedNav === value ? "2px solid #0080FF" : "",
                padding: "10px",
                cursor: "pointer",
              }}
              className="text-font3"
              onClick={() => setSelectedNav(value)}
              key={key}
            >
              {intl.formatMessage({ id: value })}
            </h6>
          ))}
        </div>
        <div className="p-3 custom-border-bottom">
          {selectedNav === TopupAndWithdrawConst.TOPUP ? (
            <TopupForm
              formikRef={formikRef}
              saveValues={saveValues}
              selAgentId={selAgentId}
              selUsername={selUsername}
              selNickname={selNickname}
              selBalance={selBalance}
            />
          ) : (
            <WithdrawForm
              formikRef={formikRef}
              saveValues={saveValues}
              selAgentId={selAgentId}
              selUsername={selUsername}
              selNickname={selNickname}
              selBalance={selBalance}
            />
          )}
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          {agentState.actionsLoading && (
            <div
              className="spinner-border me-2"
              role="status"
              style={{ scale: "0.55" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <button
            type="button"
            disabled={agentState.actionsLoading}
            className="btn btn-primary px-3"
            onClick={() => formikRef.current.submitForm()}
          >
            {selectedNav === TopupAndWithdrawConst.TOPUP
              ? intl.formatMessage({ id: "topup" })
              : intl.formatMessage({ id: "withdraw" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
