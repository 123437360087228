import * as Yup from "yup";

export const LoginSchema = (intl) => {
  return Yup.object().shape({
    username: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "usernameRequired" })),
    userPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "passwordRequired" })),
  });
};
