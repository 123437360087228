import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    dashboard: "Dashboard",
    menu: "Menu",
    changePassword: "Change Password",
    profile: "Profile",
    report: "Report",
    companyWinLoss: "Company Win/Loss",
    agentReport: "Agent Report",
    lotteryPayout: "Lottery Payout",
    userList: "User List",
    memberList: "Member List",
    agentList: "Agent List",
    subAgentList: "Sub-Agent List",
    currencyExchange: "Currency Exchange",
    logout: "Logout",
    balance: "Balance",
    member: "Member",
    agent: "Agent",
    total: "Total",
    inviteLink: "Invite Link",
    dailyCommission: "Daily Commission",
    password: "Password",
    login: "Login",
    username: "Username",
    usernameRequired: "Username Required",
    passwordRequired: "Password Required",
    error: "Error",
    newPassword: "New Password",
    oldPassword: "Old Password",
    confirmPassword: "Confirm Password",
    oldPasswordRequired: "Old Password Required ",
    newPasswordRequired: "New Password Required",
    confirmPasswordRequired: "Confirm Password Required",
    submit: "Submit",
    addSubAgent: "Create Sub-Agent",
    addAgent: "Create Agent",
    addMember: "Create Member",
    noRecordFound: "No Record Found",
    search: "Search",
    no: "No.",
    nickname: "Nickname",
    inviteBy: "Invite By",
    status: "Status",
    action: "Action",
    currency: "Currency",
    registerDate: "Register Date",
    share: "Share",
    invalidConfirmPassword: "Invalid Confirm Password",
    changePasswordSuccess: "Password Change Successful",
    success: "Success",
    delete: "Delete",
    create: "Create",
    createSubAgent: "Create Sub-Agent",
    createSubAgentSuccess: "Create Sub-Agent Successful",
    edit: "Edit",
    editSubAgentSuccess: "Edit Sub-Agent Successful",
    editSubAgent: "Edit Sub-Agent",
    updateSubAgentSuccess: "Update Sub-Agent Successful",
    updateSubAgentPermission: "Sub-Agent Permission",
    update: "Update",
    details: "Details",
    editAgent: "Edit Agent",
    editSubAgentSuccess: "Edit Agent Successful",
    editMember: "Edit Member",
    editAgentSuccess: "Edit Member Successful",
    totalAgent: "Total Agent Downline",
    totalMember: "Total Member",
    inviteby: "Invite By",
    ipaddress: "IP Address",
    regdate: "Registration Date",
    createAgentSuccess: "Create Agent Successful",
    createAgent: "Create Agent",
    createMemberSuccess: "Create Member Successful",
    createMember: "Create Member",
    searchByUsername: "Search By Username",
    fail: "Fail",
    topupAndWithdraw: "Topup And Withdraw",
    topup: "Topup",
    withdraw: "Withdraw",
    amount: "Amount",
    invalidAmount: "Invalid Amount",
    topupSuccess: "Topup Successful",
    withdrawSuccess: "Withdraw Successful",
    gamename: "Game Name",
    close: "Close",
    gameList: "Game List",
    transfer: "Transfer",
    transferin: "Transfer In",
    transferout: "Transfer Out",
    gamerate: "Game Rate",
    companyname: "Company Name",
    transferInSuccess: "Transfer In Successful",
    transferOutSuccess: "Transfer Out Successful",
    showmore: "Show More",
    showless: "Show Less",
    create: "Create",
    gameurl: "Game Url",
    copySuccessfully: "Copy Successfully",
    accountList: "Account List",
    personal: "Personal",
    createGameIdSuccess: "Create Game ID Success",
    gameId: "Game ID",
    date: "Date",
    beforeAmount: "Before Amount",
    afterAmount: "After Amount",
    platfromSetting: "Platfrom Setting",
    gameActiveList: "Game Active List",
    agentDetails: "Agent Details",
    back: "Back",
    financialReport: "Financial Report",
    totalTopup: "Total Topup",
    totalWithdraw: "Total Withdraw",
    totalWin: "Total Win",
    totalBet: "Total Bet",
    totalProfit: "Total Profit",
    agentDownlineReport: "Agent Downline Report",
    transactionReport: "Transaction Report",
  },

  [LOCALES.CHINESE]: {},
  [LOCALES.MALAY]: {},
  [LOCALES.INDO]: {},
};
