import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

export function checkMemberList(params) {
  return authPost(`${baseUrl}/Agent2.ashx`, params);
}

export function checkMemberGameList(params) {
  return authPost(`${baseUrl}/Agent4.ashx`, params);
}
export function checkMemberReportList(params) {
  return authPost(`${baseUrl}/Agent5.ashx`, params);
}
