import React, { useState } from "react";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CheckIsNull } from "../../../utils/CommonHelper";

export const getFieldCSSClasses = (touched, errors, value, readOnly) => {
  const classes = ["form-control"];

  if (readOnly) {
    classes.push("text-gray-800");
    return classes.join(" ");
  }

  if (touched && (errors || value === "error")) {
    classes.push("is-invalid");
  }

  if (value === "error") {
    classes.push("is-invalid");
  }

  if (
    touched &&
    !errors &&
    value !== null &&
    value !== "" &&
    value !== "error"
  ) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export const Input = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  type = "text",
  readOnly = false,
  placeholder,
  autoComplete,
  labelClass,
}) => {
  const [value, setValue] = useState(field.value || "");
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Validate input here to allow only alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9.]*$/;
    if (alphanumericRegex.test(inputValue) || inputValue === "") {
      setValue(inputValue);
      setFieldValue(field.name, inputValue); // Update form field value
    }
  };

  const fieldData = {
    ...field,
    value: field.value === "error" ? "" : field.value,
  };

  const inputClassName = getFieldCSSClasses(
    touched[field.name],
    errors[field.name],
    field.value,
    readOnly
  );
  const inputStyles = {
    WebkitAppearance: "none",
    MozAppearance: "textfield",
    appearance: "textfield",
  };
  const overriddenInputClassName = `${inputClassName}`;
  return (
    <>
      {label && (
        <label className={labelClass ? labelClass : `mb-2 w-100 `}>
          {label}
        </label>
      )}

      <input
        type={type}
        className={`${inputClassName}  w-100 inputStyle`}
        {...fieldData}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete={autoComplete}
        onChange={handleInputChange}
        style={{
          inputStyles,
        }}
      />
      <ErrorContainer
        isShow={touched[field.name] && errors[field.name]}
        errorMsg={errors[field.name]}
      />
    </>
  );
};
export const Input2 = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  type = "text",
  readOnly = false,
  placeholder,
  autoComplete,
  labelClass,
}) => {
  const [value, setValue] = useState(field.value || "");
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Validate input here to allow only alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
    if (alphanumericRegex.test(inputValue) || inputValue === "") {
      setValue(inputValue);
      setFieldValue(field.name, inputValue); // Update form field value
    }
  };

  const fieldData = {
    ...field,
    value: field.value === "error" ? "" : field.value,
  };

  const inputClassName = getFieldCSSClasses(
    touched[field.name],
    errors[field.name],
    field.value,
    readOnly
  );

  const overriddenInputClassName = `${inputClassName} text-white`;
  return (
    <>
      {label && (
        <label
          className={
            labelClass ? labelClass : `mb-2 phone-input w-100 text-white`
          }
        >
          {label}
        </label>
      )}

      <input
        type={type}
        className={`${inputClassName} phone-input w-100 inputStyle`}
        {...fieldData}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete={autoComplete}
        onChange={handleInputChange}
        style={{
          "::placeholder": {
            color: "grey",
          },
        }}
      />
      <ErrorContainer
        isShow={touched[field.name] && errors[field.name]}
        errorMsg={errors[field.name]}
      />
    </>
  );
};
export const PhoneInputField = ({
  values,
  setFieldValue,
  setIsShowPhoneNoError,
  isShowPhoneNoError,
  phoneNoErrorDescription,
  setPhoneNoErrorDescription,
  name,
}) => {
  const intl = useIntl();

  return (
    <>
      <PhoneInput
        className={`inputStyle1`}
        country={"my"}
        value={values[name]}
        countryCodeEditable={false}
        onChange={(phone) => {
          setFieldValue(name, phone);
          if (isShowPhoneNoError && !CheckIsNull(phone)) {
            setIsShowPhoneNoError(false);
          }
        }}
        onBlur={() => {
          if (CheckIsNull(values[name])) {
            setIsShowPhoneNoError(true);
            setPhoneNoErrorDescription(
              intl.formatMessage({ id: "phoneNoRequired" })
            );
          } else {
            setIsShowPhoneNoError(false);
          }
        }}
        inputProps={{
          style: { background: "black", color: "white" }, // Add the background style
          className: `form-control phone-input w-100 ${
            !isShowPhoneNoError && !CheckIsNull(values[name])
              ? "is-valid"
              : isShowPhoneNoError && !CheckIsNull(phoneNoErrorDescription)
              ? "is-invalid"
              : ""
          }`,
        }}
      />

      <ErrorContainer
        isShow={isShowPhoneNoError && !CheckIsNull(phoneNoErrorDescription)}
        errorMsg={phoneNoErrorDescription}
      />
    </>
  );
};

export const EmailInputField = ({
  values,
  setFieldValue,
  setIsShowGmailNoError,
  isShowGmailNoError,
  gmailNoErrorDescription,
  setGmailNoErrorDescription,
  name,
}) => {
  const intl = useIntl();
  const [emailValue, setEmailValue] = useState(values[name] || "");
  return (
    <>
      <input
        type="email"
        value={emailValue}
        onChange={(event) => {
          const email = event.target.value;
          setEmailValue(email);
          setFieldValue(name, email);
          if (isShowGmailNoError && !CheckIsNull(email)) {
            setIsShowGmailNoError(false);
          }
        }}
        onBlur={() => {
          const email = emailValue;
          if (CheckIsNull(email)) {
            setIsShowGmailNoError(true);
            setGmailNoErrorDescription(
              intl.formatMessage({ id: "emailRequired" })
            );
          } else if (!isValidEmail(email)) {
            // Add email validation function
            setIsShowGmailNoError(true);
            setGmailNoErrorDescription(
              intl.formatMessage({ id: "invalidEmailFormat" })
            );
          } else {
            setIsShowGmailNoError(false);
          }
        }}
        style={{ background: "black", color: "white" }} // Add the background style
        className={`form-control phone-input w-100 ${
          !isShowGmailNoError && !CheckIsNull(emailValue)
            ? "is-valid"
            : isShowGmailNoError && !CheckIsNull(gmailNoErrorDescription)
            ? "is-invalid"
            : ""
        }`}
      />

      <ErrorContainer
        isShow={isShowGmailNoError && !CheckIsNull(gmailNoErrorDescription)}
        errorMsg={gmailNoErrorDescription}
      />
    </>
  );
};

// Function to validate email format
const isValidEmail = (email) => {
  // Regular expression for basic email format validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const ErrorContainer = ({ errorMsg, isShow, className }) =>
  isShow ? (
    <div
      className={`fv-plugins-message-container text-danger ${
        className ? className : ""
      }`}
    >
      <div className="fv-help-block">
        <span role="alert">{errorMsg}</span>
      </div>
    </div>
  ) : null;
