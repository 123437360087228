import { useIntl } from "react-intl";
import { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/MemberAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../../common/Modal";
import { TopupAndWithdrawConst } from "../../../../constants/TopUpAndWithdrawConst";
import { TransferInForm } from "./TransferInForm";
import { TransferOutForm } from "./TransferOutForm";
import {
  CopyToClipboard,
  CopyToClipboard1,
} from "../../../../utils/CommonHelper";
import SweetAlert2 from "react-sweetalert2";

export const TransferDialog = ({
  selMemberId,
  selCompanyName,
  selBalance,
  selCompanyCode,
  selGameRate,
  selGameUsername,
  selGamePass,
  selGameUrl,
}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [selectedNav, setSelectedNav] = useState(TopupAndWithdrawConst.TOPUP);
  const [swalProps, setSwalProps] = useState({});
  const [isExtended, setIsExtended] = useState(false);
  const memberState = useSelector(({ member }) => member, shallowEqual);

  const saveValues = (queryParams) => {
    dispatch(actions.memberTransfer(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text:
            queryParams.action === "membertopup_direct"
              ? intl.formatMessage({ id: "transferInSuccess" })
              : intl.formatMessage({ id: "transferOutSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            window.location.reload();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <Modal
        modalId="TransferModal"
        title={intl.formatMessage({ id: "transfer" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="d-flex">
          {Object.entries(TopupAndWithdrawConst).map(([key, value]) => (
            <h6
              style={{
                color: selectedNav === value ? "#0080FF" : "",
                borderBottom: selectedNav === value ? "2px solid #0080FF" : "",
                padding: "10px",
                cursor: "pointer",
              }}
              className="text-font3"
              onClick={() => setSelectedNav(value)}
              key={key}
            >
              {intl.formatMessage({ id: value })}
            </h6>
          ))}
        </div>
        <div className="p-3 custom-border-bottom">
          {selectedNav === TopupAndWithdrawConst.TOPUP ? (
            <TransferInForm
              formikRef={formikRef}
              saveValues={saveValues}
              selMemberId={selMemberId}
              selCompanyName={selCompanyName}
              selCompanyCode={selCompanyCode}
              selBalance={selBalance}
              selGameRate={selGameRate}
            />
          ) : (
            <TransferOutForm
              formikRef={formikRef}
              saveValues={saveValues}
              selMemberId={selMemberId}
              selCompanyName={selCompanyName}
              selCompanyCode={selCompanyCode}
              selBalance={selBalance}
            />
          )}
        </div>
        {isExtended && selGameUrl != "" && (
          <div
            className="d-flex tansferDialogCard"
            style={{
              margin: "0 1vh",
            }}
          >
            <div>
              {intl.formatMessage({ id: "gameurl" }) + " : " + selGameUrl}

              <div>
                {intl.formatMessage({ id: "username" }) +
                  " : " +
                  selGameUsername}
              </div>
              <div>
                {intl.formatMessage({ id: "password" }) + " : " + selGamePass}
              </div>
            </div>
            <div
              className="d-flex"
              style={{
                width: "40%",
                justifyContent: "end",
                margin: "auto auto",
              }}
            >
              <i
                className="bi bi-copy"
                onClick={() => {
                  CopyToClipboard(
                    intl.formatMessage({ id: "gameurl" }) +
                      " : " +
                      selGameUrl +
                      "\n" +
                      intl.formatMessage({ id: "username" }) +
                      " : " +
                      selGameUsername +
                      "\n" +
                      +intl.formatMessage({ id: "password" }) +
                      " : " +
                      selGamePass,
                    setSwalProps,
                    intl
                  );
                }}
              />
            </div>
          </div>
        )}
        {selGameUrl != "" && (
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setIsExtended(!isExtended)}
            >
              {intl.formatMessage({
                id: isExtended ? "showless" : "showmore",
              })}
              <i
                className={`bi ${
                  isExtended ? "bi-arrow-up-short" : "bi-arrow-down-short"
                }`}
              />
            </button>
          </div>
        )}
        <div className="p-2 d-flex justify-content-end align-items-center">
          {memberState.actionsLoading && (
            <div
              className="spinner-border me-2"
              role="status"
              style={{ scale: "0.55" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <button
            type="button"
            disabled={memberState.actionsLoading}
            className="btn btn-primary px-3"
            onClick={() => formikRef.current.submitForm()}
          >
            {selectedNav === TopupAndWithdrawConst.TOPUP
              ? intl.formatMessage({ id: "transferin" })
              : intl.formatMessage({ id: "transferout" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
