import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { GameActiveList } from "../../modules/platformSetting/components/GameActiveList";

const PlatformSettingContext = createContext();

const PlatformSettingWrapper = () => {
  const value = {};

  return (
    <PlatformSettingContext.Provider value={value}>
      <Routes>
        <Route path="/" element={<GameActiveList />} />
      </Routes>
    </PlatformSettingContext.Provider>
  );
};

export { PlatformSettingWrapper };
