import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const memberState = {
  memberLists: null,
  addMemberLists: null,
  updateMemberLists: null,
  editMemberLists: null,
  memberGameLists: null,
  memberGameListsBalance: null,
  memberTransfer: null,
  addGameId: null,
  memberReportLists: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const memberSlice = createSlice({
  name: "member",
  initialState: memberState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },

    memberListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberLists = action.payload.data ?? {};
    },
    addMemberFetch: (state, action) => {
      state.actionsLoading = false;
      state.addMemberLists = action.payload.data ?? {};
    },
    updateMemberFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateMemberLists = action.payload.data ?? {};
    },
    editMemberFetch: (state, action) => {
      state.actionsLoading = false;
      state.editMemberLists = action.payload.data ?? {};
    },
    memberGameListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberGameLists = action.payload.data ?? {};
    },
    memberGameListsBalanceFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberGameListsBalance = action.payload.data ?? {};
    },
    memberTransferFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberTransfer = action.payload.data ?? {};
    },
    addGameIdFetch: (state, action) => {
      state.actionsLoading = false;
      state.addGameId = action.payload.data ?? {};
    },
    memberReportListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberReportLists = action.payload.data ?? {};
    },
  },
});
