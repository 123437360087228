import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./MainCRUD";
import { mainSlice } from "./MainSlice";

const { actions } = mainSlice;

export const callGroupCredit = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkGroupCredit(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.groupCreditFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkGroupCredit");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
