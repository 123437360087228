import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../../common/control/InputField";

export const TransferInForm = ({
  formikRef,
  saveValues,
  selMemberId,
  selCompanyName,
  selBalance,
  selCompanyCode,
  selGameRate,
}) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        amount: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (values.amount != null || values.amount != "0") {
          let queryParams = {
            action: "membertopup_direct",
            memberid: selMemberId,
            companycode: selCompanyCode,
            amount: values.amount,
          };
          saveValues(queryParams);
        } else {
          formikHelpers.setFieldError(
            "amount",
            intl.formatMessage({ id: "invalidAmount" })
          );
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div>
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div>{selCompanyName}</div>
              <div>
                {intl.formatMessage({ id: "balance" }) + " : " + selBalance}
              </div>
            </div>
          </div>
          <div
            className="my-2"
            style={{ borderBottom: "1px solid rgb(175, 164, 164, 0.2)" }}
          ></div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "amount" })}
            </label>

            <Field
              name="amount"
              component={Input}
              placeholder="0.00"
              autoComplete="off"
              type="number"
            />
          </div>
          <div
            className="d-flex"
            style={{
              fontSize: "1.1vh",
              color: "gray",
            }}
          >
            {intl.formatMessage({ id: "gamerate" }) + " : " + selGameRate}
          </div>
        </Form>
      )}
    </Formik>
  );
};
