import * as Yup from "yup";

export const editPasswordSchema = (intl) => {
  return Yup.object().shape({
    oldPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "oldPasswordRequired" })),
    newPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "newPasswordRequired" })),
    confirmPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "confirmPasswordRequired" })),
  });
};
