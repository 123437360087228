import React, { useEffect, useState } from "react";
import "./sideBar.css";
import { useIntl } from "react-intl";
import Logo from "./Logo";
import DarkMode from "./DarkMode";
import { useAuth } from "../../auth/components/AuthInit";
import { useLocation, useNavigate } from "react-router-dom";

export default function SideBar() {
  const intl = useIntl();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [activeItem, setActiveItem] = useState("dashboard");

  useEffect(() => {
    if (path.includes("/changePassword")) {
      setActiveItem("changePassword");
    } else if (path.includes("/profile")) {
      setActiveItem("profile");
    } else if (path.includes("/companyWinLose")) {
      setActiveItem("companyWinLose");
    } else if (path.includes("/agentDownlineReport")) {
      setActiveItem("agentDownlineReport");
    } else if (path.includes("/transactionReport")) {
      setActiveItem("transactionReport");
    } else if (path.includes("/agentReport")) {
      setActiveItem("agentReport");
    } else if (path.includes("/lotteryPayout")) {
      setActiveItem("lotteryPayout");
    } else if (path.includes("/searchByUsername")) {
      setActiveItem("searchByUsername");
    } else if (path.includes("/home")) {
      setActiveItem("searchByUsername");
    } else if (path.includes("/agentList")) {
      setActiveItem("accountList");
    } else if (path.includes("/memberList")) {
      setActiveItem("accountList");
    } else if (path.includes("/accountList")) {
      setActiveItem("accountList");
    } else if (path.includes("/subAgentList")) {
      setActiveItem("subAgentList");
    } else if (path.includes("/currencyExchange")) {
      setActiveItem("currencyExchange");
    } else if (path.includes("/gameActiveList")) {
      setActiveItem("gameActiveList");
    } else if (path.includes("/agentReport")) {
      setActiveItem("agentReport");
    } else {
      setActiveItem("dashboard");
    }
  }, [path]);
  const handleItemClick = (item) => {
    setActiveItem(item);
    if (window.innerWidth <= 768) {
      document.body.classList.toggle("toggle-sidebar");
    }
  };
  const handleClose = () => {
    document.body.classList.toggle("toggle-sidebar");
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <div className={"sidebar-container"}>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <div
            className="d-flex"
            style={{ margin: "5px", justifyContent: "space-between" }}
          >
            <Logo openfrom={"home"} />
            <i className="bi bi-x" onClick={() => handleClose()} />
          </div>
          <hr />
          <div
            className="d-flex"
            style={{ margin: "5px", justifyContent: "space-between" }}
          >
            <i
              className="bi bi-x"
              style={{ visibility: "hidden" }}
              onClick={() => handleClose()}
            />
            <DarkMode />
          </div>
          {/* <li className="nav-item">
            <span>{intl.formatMessage({ id: "menu" })}</span>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("dashboard");
              navigate("/home");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "dashboard" ? "#4154f1" : "",
                background: activeItem === "dashboard" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-grid"></i>
              <span>{intl.formatMessage({ id: "dashboard" })}</span>
            </div>
          </li> */}

          <li className="nav-item">
            <span>{intl.formatMessage({ id: "accountList" })}</span>
          </li>

          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("searchByUsername");
              navigate("/searchByUsername");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "searchByUsername" ? "#4154f1" : "",
                background: activeItem === "searchByUsername" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-search"></i>
              <span>{intl.formatMessage({ id: "searchByUsername" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("accountList");
              navigate("/accountList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "accountList" ? "#4154f1" : "",
                background: activeItem === "accountList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-person-fill-add"></i>
              <span>{intl.formatMessage({ id: "accountList" })}</span>
            </div>
          </li>
          <li className="nav-item">
            <span>{intl.formatMessage({ id: "report" })}</span>
          </li>
          {/* <li
            className="nav-item"
            onClick={() => handleItemClick("companyWinLoss")}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "companyWinLoss" ? "#4154f1" : "",
                background: activeItem === "companyWinLoss" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-buildings"></i>
              <span>{intl.formatMessage({ id: "companyWinLoss" })}</span>
            </div>
          </li> */}
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("agentReport");
              navigate("/agentReport");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "agentReport" ? "#4154f1" : "",
                background: activeItem === "agentReport" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-clipboard2-data"></i>
              <span>{intl.formatMessage({ id: "financialReport" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("agentDownlineReport");
              navigate("/agentReport/agentDownlineReport");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "agentDownlineReport" ? "#4154f1" : "",
                background:
                  activeItem === "agentDownlineReport" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-clipboard2-pulse"></i>
              <span>{intl.formatMessage({ id: "agentDownlineReport" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("transactionReport");
              navigate("/agentReport/transactionReport");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "transactionReport" ? "#4154f1" : "",
                background: activeItem === "transactionReport" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-file-earmark-text"></i>
              <span>{intl.formatMessage({ id: "transactionReport" })}</span>
            </div>
          </li>
          <li className="nav-item">
            <span>{intl.formatMessage({ id: "platfromSetting" })}</span>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("gameActiveList");
              navigate("/gameActiveList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "gameActiveList" ? "#4154f1" : "",
                background: activeItem === "gameActiveList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-ban"></i>
              <span>{intl.formatMessage({ id: "gameActiveList" })}</span>
            </div>
          </li>
          <li className="nav-item">
            <span>{intl.formatMessage({ id: "personal" })}</span>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("subAgentList");
              navigate("/subAgentList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "subAgentList" ? "#4154f1" : "",
                background: activeItem === "subAgentList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-person-lines-fill"></i>
              <span>{intl.formatMessage({ id: "subAgentList" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("changePassword");
              navigate("/changePassword");
            }}
          >
            <div
              className="nav-link"
              style={{
                color: activeItem === "changePassword" ? "#4154f1" : "",
                background: activeItem === "changePassword" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-pen"></i>
              <span>{intl.formatMessage({ id: "changePassword" })}</span>
            </div>
          </li>

          <li className="nav-item" onClick={() => handleItemClick("profile")}>
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "profile" ? "#4154f1" : "",
                background: activeItem === "profile" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-person"></i>
              <span>{intl.formatMessage({ id: "profile" })}</span>
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-logout" onClick={handleLogout}>
              <i className="bi bi-box-arrow-left"></i>
              <span>{intl.formatMessage({ id: "logout" })}</span>
            </div>
          </li>
        </ul>
      </aside>
    </div>
  );
}
