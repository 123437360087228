import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../common/control/InputField";

export const EditSubAgentForm = ({
  formikRef,
  saveValues,
  agentId,
  oldNickname,
}) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        username: "",
        nickname: "",
        password: "",
        confirmPassword: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (values.password === values.confirmPassword) {
          if (values.nickname == "") {
            let queryParams = {
              action: "editsubagent",
              password: values.password,
              nickname: oldNickname,
              selagentid: agentId,
            };
            saveValues(queryParams);
          } else {
            let queryParams = {
              action: "editsubagent",
              password: values.password,
              nickname: values.nickname,
              selagentid: agentId,
            };
            saveValues(queryParams);
          }
        } else {
          formikHelpers.setFieldError(
            "confirmPassword",
            intl.formatMessage({ id: "invalidConfirmPassword" })
          );
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "password" })}
            </label>

            <Field
              name="password"
              component={Input}
              placeholder={intl.formatMessage({ id: "password" })}
              autoComplete="off"
              type="password"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "confirmPassword" })}
            </label>

            <Field
              name="confirmPassword"
              component={Input}
              placeholder={intl.formatMessage({ id: "confirmPassword" })}
              autoComplete="off"
              type="password"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "nickname" })}
            </label>

            <Field
              name="nickname"
              component={Input}
              placeholder={intl.formatMessage({ id: "nickname" })}
              autoComplete="off"
              type="text"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
