import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import SweetAlert2 from "react-sweetalert2";
import { LoginFormTab } from "./loginTab/LoginFormTab";
import Logo from "../../slidebar/components/Logo";
import DarkMode from "../../slidebar/components/DarkMode";
import "./Login.css";

export const LoginRegisterDialog = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [tempAuth, setTempAuth] = useState();

  return (
    <div className="login-register-dialog">
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
      />
      <div className="header-container">
        <Logo openfrom={"login"} />
      </div>

      <div className="main-content">
        <LoginFormTab setSwalProps={setSwalProps} setTempAuth={setTempAuth} />
      </div>
      <div>
        <DarkMode />
      </div>
    </div>
  );
};
