import { useIntl } from "react-intl";
import { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/MemberAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";
import { useAuth } from "../../auth/components/AuthInit";

export const CreateGameIdDialog = ({
  selMemberId,
  selCompanyCode,
  selGameExist,
}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const { auth, saveAuth } = useAuth();
  const memberState = useSelector(({ member }) => member, shallowEqual);

  useEffect(() => {
    if (
      selCompanyCode !== "" &&
      selCompanyCode !== null &&
      selCompanyCode !== undefined &&
      selGameExist !== "true"
    ) {
      const queryParams = {
        action: "creategameacct",
        memberid: selMemberId,
        companycode: selCompanyCode,
      };

      dispatch(actions.createGameId(queryParams));
    }
  }, [selCompanyCode, selMemberId]);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="createIdModal"
        title={intl.formatMessage({ id: "gameId" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 custom-border-bottom">
          {memberState.addGameId && (
            <div>
              {memberState.addGameId.code === "0" ? (
                <div
                  className="d-flex"
                  style={{
                    margin: "0 1vh",
                  }}
                >
                  <div>
                    <div>
                      {intl.formatMessage({ id: "gameurl" }) +
                        " : " +
                        memberState.addGameId.relateurl}
                    </div>
                    <div>
                      {intl.formatMessage({ id: "username" }) +
                        " : " +
                        memberState.addGameId.gameusername}
                    </div>
                    <div>
                      {intl.formatMessage({ id: "password" }) +
                        " : " +
                        memberState.addGameId.gamepassword}
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      width: "40%",
                      justifyContent: "end",
                      margin: "auto auto",
                    }}
                  >
                    <i
                      className="bi bi-copy"
                      onClick={() => {
                        CopyToClipboard(
                          intl.formatMessage({ id: "gameurl" }) +
                            " : " +
                            memberState.addGameId.relateurl +
                            "\n" +
                            intl.formatMessage({ id: "username" }) +
                            " : " +
                            memberState.addGameId.gameusername +
                            "\n" +
                            +intl.formatMessage({ id: "password" }) +
                            " : " +
                            memberState.addGameId.gamepassword,
                          setSwalProps,
                          intl
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div>{memberState.addGameId.message}</div>
              )}
            </div>
          )}
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          {memberState.actionsLoading && (
            <div
              className="spinner-border me-2"
              role="status"
              style={{ scale: "0.55" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <button
            type="button"
            disabled={memberState.actionsLoading}
            className="btn btn-primary px-3"
            onClick={() => {
              dismissBtnRef.current.click();
              window.location.reload();
            }}
          >
            {intl.formatMessage({ id: "close" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
